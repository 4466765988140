import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FlexLayoutModule} from '@angular/flex-layout';
import {MatCardModule} from '@angular/material/card';

import {DataService} from '../Interfaces/data.service';
import {DashboardComponent} from './dashboard-container/dashboard.component';

@NgModule({
  declarations: [DashboardComponent],
  exports: [
  ],
  imports: [
    CommonModule,
    MatCardModule,
    FlexLayoutModule,
  ],
  providers: [DataService],
})
export class DashboardModule {}
