<mat-card style="width: 100%; margin-left: 2%">
  <mat-card-content class="content-card">
    <div>
      <table>
        <tr>
          <th class="target">Gesamtbearbeitungszeit:</th>
          <div *ngIf="keyFigure.totalProcessingTime != null">
            <td class="target"> {{ keyFigure.totalProcessingTime * timeFactor }} {{ timeLabel }}</td>
          </div>
        </tr>
        <tr>
          <th class="target">Mittlere Durchlaufzeit:</th>
          <div *ngIf="keyFigure.avgProcessingTime != null">
            <td class="target"> {{ keyFigure.avgProcessingTime * timeFactor }} {{ timeLabel }}</td>
          </div>
        </tr>
        <tr>
          <th class="target">Mittlere Verspätung (vMid):</th>
          <div *ngIf="keyFigure.vMid != null">
            <td class="target"> {{ keyFigure.vMid * timeFactor }} {{ timeLabel }}</td>
          </div>
        </tr>
        <tr>
          <th class="target">Maximale Verspätung (vMax):</th>
          <div *ngIf="keyFigure.vMax != null">
            <td class="target"> {{ keyFigure.vMax * timeFactor }} {{ timeLabel }}</td>
          </div>
        </tr>
        <tr>
          <th class="target">Varianz der Verspätung (vVar):</th>
          <div *ngIf="keyFigure.vVar != null">
            <td class="target"> {{ keyFigure.vVar * timeFactor * timeFactor }} {{ timeLabel }}<sup>2</sup></td>
          </div>
        </tr>
        <tr>
          <th class="target">Summe der Verspätungen (vSum):</th>
          <div *ngIf="keyFigure.vSum != null">
            <td class="target"> {{ keyFigure.vSum * timeFactor }} {{ timeLabel }}</td>
          </div>
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
