import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../Interfaces/data.service';
import { FormatDataService } from '../Interfaces/format-data.service';
import { ExponatRessourcenbelegungsplanungSolutionContainerComponent } from './solution/container/container.component';
import { SolutionGanttComponent } from './solution/gantt/gantt.component';
import { SolutionOrderComponent } from './solution/order/order.component';
import { SolutionValuesComponent } from './solution/values/values.component';
import { ExponatRessourcenbelegungsplanungTaskContainerComponent } from './task/container/container.component';
import { TaskGanttComponent } from './task/gantt/gantt.component';
import { TaskOrderComponent } from './task/order/order.component';
import { TaskValuesComponent } from './task/values/values.component';

@NgModule({
  declarations: [
    SolutionGanttComponent,
    SolutionOrderComponent,
    SolutionValuesComponent,
    ExponatRessourcenbelegungsplanungSolutionContainerComponent,
    ExponatRessourcenbelegungsplanungTaskContainerComponent,
    TaskOrderComponent,
    TaskGanttComponent,
    TaskValuesComponent,
  ],

  exports: [],

  imports: [
    CommonModule,
    DragDropModule,
    NgbTooltipModule,
    MatTooltipModule,
    MatCardModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatButtonModule,
  ],
  providers: [DataService, FormatDataService],
})
export class ExponatRessourcenbelegungsplanungModule {}
