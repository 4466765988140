import { Component, Input } from '@angular/core';

import { IOrder } from '../../../Interfaces/IOrder';
import { ITask } from '../../../Interfaces/ITask';

@Component({
  selector: 'app-gantt-solution',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css'],
})
export class SolutionGanttComponent {
  @Input() orders: IOrder[][];
  @Input() patterns: string[];
  @Input() stations: number[][];
  @Input() stationCount: number;
  @Input() task: ITask;
  @Input() exponatType: string;
  @Input() NotUserSolution: number;
}
