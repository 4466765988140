<div class="dashboardContainer">
  <h3 class="welcome-headline" style="text-align: center; margin-bottom: 2%">Herzlich Willkommen im digitalen Exponat
    der Ressourcenbelegungsplanung</h3>
  <br>
  <br>
  <div style="justify-content: center" class="content" fxLayout="row wrap" fxLayoutGap="16px grid">
    <div fxFlex="30%" fxFlex.xs="100%" fxFlex.sm="50%" *ngFor="let casestudy of casestudies">
      <div *ngIf="casestudy.exponatType== 'EXPONAT1'">
      <mat-card class="mat-elevation-z4">
        <mat-card-header style="height: 50px">
          <mat-card-title style="font-size: large; alignment: center" class="title">
            {{casestudy.title}}
          </mat-card-title>
        </mat-card-header>
        <hr>
        <mat-card-content style="height: 100px; margin-left: 2%; margin-top: 2%;">
          <p>{{casestudy.shortDescription}}</p>
        </mat-card-content>
        <hr>
        <mat-card-action class="buttons">
          <button class="btn btn-primary" (click)="nextPage(casestudy)">Starten</button>
        </mat-card-action>
      </mat-card>
      </div>
    </div>
  </div>
</div>
