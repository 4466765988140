<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="task">
  <div class="solution">
    <h3 class="headline" style="text-align: center">Vergleichen Sie Ihre Lösung</h3>
    <hr />

    <!-- User Solution -->
    <div class="user-solution">
      <span class="headline-solution headline-solution-user" style="font-weight: bold; margin-left: 2%"
        >Ihre Lösung</span
      >
      <div class="user-gannt">
        <app-gantt-solution
          [task]="task"
          [orders]="solutionUser"
          [patterns]="patterns"
          [stations]="stations"
          [stationCount]="stationCount"
          [exponatType]="exponatType"
          [NotUserSolution]="0"
        >
        </app-gantt-solution>
      </div>
      <div class="user-value" *ngIf="allOrdersInStation">
        <div *ngIf="keyFigureValid">
          <app-values-solution
            [targetCriterion]="task.targetCriterion"
            [timeFactor]="task.timeFactor"
            [keyFigure]="keyFigureUser"
            [timeLabel]="timeLabel"
            [exponatType]="exponatType"
          >
          </app-values-solution>
        </div>
        <div class="user-value" *ngIf="!keyFigureValid">
          <p style="color: red; margin-left: 2%">
            Berechnung nicht möglich. Überprüfen Sie die Anordnung der Operationen.<br />
            Achten Sie dabei auf die richtige Station, die Reihenfolge sowie eventuelle Abhängigkeiten.<br />
          </p>
        </div>
      </div>
      <div class="user-value" *ngIf="!allOrdersInStation">
        <p style="color: red; margin-left: 2%">Sie haben nicht alle Operationen verteilt. Berechnung nicht möglich.</p>
      </div>
    </div>

    <hr />
    <!-- optimal solution -->
    <!--div class="opti-solution1"-->
    <div>
      <div>
        <span class="headline-solution headline-solution-opti" style="font-weight: bold; margin-left: 2%"
          >Optimale Lösung (gelöst mit {{ task.optimalAlgorithm }})</span
        >
      </div>
      <div class="opti-gannt">
        <app-gantt-solution
          [task]="task"
          [orders]="ordersInAreaOrders"
          [stations]="stations"
          [patterns]="patterns"
          [stationCount]="stationCount"
          [exponatType]="exponatType"
          [NotUserSolution]="1"
        >
        </app-gantt-solution>
      </div>
      <div class="opti-value">
        <app-values-solution
          [targetCriterion]="targetCriterion"
          [timeFactor]="task.timeFactor"
          [keyFigure]="keyFigureOptimal"
          [timeLabel]="timeLabel"
          [exponatType]="exponatType"
        >
        </app-values-solution>
      </div>
    </div>

    <div class="buttons" *ngIf="task.optimalAlgorithm === 'Johnson'" style="margin-top: 1%;">
      <button class="btn-check btn btn-primary" (click)="displayAlgorithmInfo(algorithmInfo)">Johnson-Bedingung und Johnson-Verfahren</button>
    </div>

    <hr />

    <!-- checked the targetCriterion-->
    <div class="feedback-solution" *ngIf="task.targetCriterion === 'MIN_TOTALTIME'">
      <span style="font-weight: bold; font-size: 1.1em">Das Zielkriterium war: Kürzeste Gesamtbearbeitungszeit</span>
      <br />
    </div>
    <div class="feedback-solution" *ngIf="task.targetCriterion === 'MIN_VMAX'">
      <span style="font-weight: bold; font-size: 1.1em"
        >Das Zielkriterium war: Minimierung der maximalen Verspätung</span
      >
      <br />
    </div>
    <div class="feedback-solution" *ngIf="task.targetCriterion === 'MIN_VSUM'">
      <span style="font-weight: bold; font-size: 1.1em"
        >Das Zielkriterium war: Minimierung der Summe der Verspätungen</span
      >
      <br />
    </div>
    <div class="feedback-solution" *ngIf="task.targetCriterion === 'MIN_VMID'">
      <span style="font-weight: bold; font-size: 1.1em"
        >Das Zielkriterium war: Minimierung der mittleren Verspätung</span
      >
      <br />
    </div>
    <div class="feedback-solution" *ngIf="task.targetCriterion === 'MIN_VVAR'">
      <span style="font-weight: bold; font-size: 1.1em">Das Zielkriterium war: Minimierung der Streuung (Varianz)</span>
      <br />
    </div>
    <div>
      <div class="feedback-solution" *ngIf="!allOrdersInStation">
        <span style="color: red; font-size: 1.1em">
          Berechnung konnte nicht durchgeführt werden, da nicht alle Aufträge verteilt wurden</span
        >
        <br />
      </div>
      <div class="feedback-solution" *ngIf="targetAchieved && allOrdersInStation && keyFigureValid">
        <span style="font-size: 1.1em">Glückwunsch, das Zielkriterium wurde erfüllt</span> <br />
      </div>
      <div class="feedback-solution" *ngIf="!targetAchieved && allOrdersInStation && keyFigureValid">
        <span style="font-size: 1.1em">
          Es besteht eine Differenz zur optimalen Lösung von: {{ differenceToTarget * task.timeFactor }} {{ timeLabel }}
        </span>
        <br />
      </div>
    </div>
    <hr />

    <div class="buttons">
      <button class="btn-check btn btn-dark" (click)="back(goBack)">Zurück</button>
      <button style="margin-left: 2%" class="btn-check btn btn-primary" (click)="next()">Weiter</button>
    </div>

    <!--Dialog will just open if the user is pressing zurück Button -->
    <ng-template #goBack let-modal>
      <div class="modal-header">
        <h4 class="modal-title" id="modal-go-back">Wollen Sie zurück zur Startseite?</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <p>Ihr aktueller Stand wird nicht gespeichert!</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="modal.close(); goBackToDashboard()">Ja</button>
        <button type="button" class="btn btn-dark" (click)="modal.close()">Nein</button>
      </div>
    </ng-template>

    <ng-template #algorithmInfo>
      <div class="scrollable-container">
        Johnson-Bedingung:
        <br/>
        <br/>
        <img src="../../../../assets/general/Johnson-Bedingung.png" alt="Johnson-Bedingung">
        <br/>
        <br/>
        <br/>
        Aus der Johnson-Bedingung ergibt sich das folgende Verfahren (Johnson-Algorithmus).
        <br/>
        <br/>
        Voraussetzung:
        <br/>
        <br/>
        <img src="../../../../assets/general/Johnson-Algorithmus-Voraussetzungen.png" alt="Johnson-Algorithmus-Voraussetzungen">
        <br/>
        <br/>
        Variablen:
        <br/>
        <br/>
        <img src="../../../../assets/general/Johnson-Algorithmus-Variablen.png" alt="Johnson-Algorithmus-Variablen">
        <br/>
        <br/>
        Algorithmus:
        <br/>
        <br/>
        <img src="../../../../assets/general/Johnson-Algorithmus-Anweisungen.png" alt="Johnson-Algorithmus-Anweisungen">
      </div>
    </ng-template>
  </div>
</ng-container>
