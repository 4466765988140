import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {DataService} from '../Interfaces/data.service';
import {CaseStudyInfoComponent} from './case-study-info/case-study-info.component';
import {TaskIntroductionComponent} from './task-introduction/task-introduction.component';

@NgModule({
  declarations: [CaseStudyInfoComponent, TaskIntroductionComponent],
  exports: [
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    MatButtonModule
  ],
  providers: [DataService],
})
export class InformationModule {}
