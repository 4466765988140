import {CdkDragDrop, copyArrayItem, moveItemInArray} from '@angular/cdk/drag-drop';
import {Component, OnInit} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {DataService} from '../../../Interfaces/data.service';
import {FormatDataService} from '../../../Interfaces/format-data.service';
import {IOrder} from '../../../Interfaces/IOrder';
import {IPermHistoryRow} from '../../../Interfaces/IPermHistoryRow';
import {ITask} from '../../../Interfaces/ITask';
import {IWorkstep} from '../../../Interfaces/IWorkstep';

@Component({
  selector: 'app-exponat-ressourcenbelegungsplanung-task-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
})
export class ExponatRessourcenbelegungsplanungTaskContainerComponent implements OnInit {
  constructor(
    private dataFormatService: FormatDataService,
    private router: Router,
    private modalService: NgbModal,
    private dataService: DataService,
    private route: ActivatedRoute,
    public dialog: MatDialog
  ) {
    this.route.params.subscribe((params) => (this.currentTask = params.id));
    // refresh, if the user is changing the position of the device
    // tslint:disable-next-line:only-arrow-functions typedef
    window.onorientationchange = function () {
      const orientation = window.orientation;
      switch (orientation) {
        case 0:
        case 90:
        case -90:
          window.location.reload();
          break;
      }
    };
    // Alert Message, suggest the user to rotate the screen of the device in landscape mode
    if ((window.orientation as number) !== 90 && window.orientation !== undefined) {
      alert('Tipp: für eine bessere Übersicht, bitte in das Querformat wechseln!');
    }
  }
  isLoading: boolean;
  allOrdersInStation: boolean;
  stationCount;

  ordersInAreaStation: IOrder[][] = [];
  workstepInQueueGrid: IWorkstep[][] = [];

  ordersInAreaOrders: IOrder[][] = [];

  numberOfOrders: number;
  stations: number[][] = [];
  xco = 0;
  yco = 0;
  station;
  changedStyleIndex;
  changedStyleStation;
  changedStyleTime;
  keyFigure;
  task: ITask;
  currentTask: string;
  exponatType: string;
  orderFromWorkstep;

  columnNumber: number;
  rowNumber: number;
  patterns: string[];

  pickUpColumnNumber: number;
  pickUpRowNumber: number;
  moveInGridIsActive = false;
  queueGridMovedWorkstep: IWorkstep;
  dropElement;
  maxNumberOfWorksteps = 0;
  time = 0;
  actualTimeStamp = 0;
  actualUserKeyFigures;
  timeLabel: string;

  IdOfActualWorkstep: string[] = [];

  additon = 0;
  timeold = 0;

  listOfOrders: IOrder[] = [];
  calculatedTask: ITask;
  workstepsInGantt: IWorkstep[][] = [];

  helpModalOpen = false;
  nextModalOpen = false;
  backModalOpen = false;

  permHistoryList: IPermHistoryRow[] = [];
  orderIdMap = new Map<string, number>();

  async ngOnInit(): Promise<void> {
    // the progress spinner is loading
    this.isLoading = true;
    // receive data of the current task
    this.task = await this.dataService.getTask(this.currentTask); // Backend
    // receive the exponatType from local-storage
    this.exponatType = JSON.parse(window.sessionStorage.getItem('exponatType'));
    // if the task is finish loading the progress spinner will stop
    if (this.task !== undefined) {
      this.isLoading = false;
    }

    for (const [i, order] of this.task.orderList.entries()) {
      if (this.maxNumberOfWorksteps < order.workstepList.length) {
        this.maxNumberOfWorksteps = order.workstepList.length;
      }
      this.orderIdMap.set(order.name, i + 1);
    }

    // save the number of orders
    this.numberOfOrders = this.task.numberOfOrders;
    // set x and y coordinates to 0
    this.xco = 0;
    this.yco = 0;
    // save the number of stations
    this.stationCount = this.task.numberOfStations;
    // settings for the gantt + initial the to dimensional array ordersInAreaOrders with data
    for (let i = 1; i <= this.stationCount; i++) {
      const ordersInStation = this.dataFormatService.getIOrderData(this.task, i, String(this.exponatType));
      this.ordersInAreaOrders.push(ordersInStation);

      const initialArray: IOrder[] = [];
      this.ordersInAreaStation.push(initialArray);
      const initialArray1: IWorkstep[] = [];
      this.workstepInQueueGrid.push(initialArray1);

      const station = new Array<number>(30);
      this.stations.push(station);
    }
  }

  // get the position of mouse/finger and call methods which are necessary during the user is drag an item
  mousePos(obj): void {
    this.xco = obj.x;
    this.yco = obj.y;
    this.columnNumber = obj.index;
    const btnStation = document.getElementById('btnStation' + this.columnNumber + 'Container');
    const pos = btnStation.getBoundingClientRect();
    const isOverlay = this.xco > pos.left && this.xco < pos.right && this.yco > pos.top && this.yco < pos.bottom;
    this.handleMovedObject(obj.order, isOverlay);
    this.isDragPossible(obj.order);
  }

  // Event-Binding... - calls the next method dropOrder()
  dropTmp(obj): void {
    this.dropOrder(obj.event, obj.i);
  }

  // highlight the not allowed area in the gantt-diagramm grey (prio-rules)
  isDragPossible(order: IOrder): void {
    const stationIndex = this.getStationByOrder(order);

    this.station = document.getElementById('btnStation' + stationIndex + 'Container').querySelectorAll('div.btn_cell');
    for (let j = 0; j < this.ordersInAreaStation.length; j++) {
      if (j === stationIndex) {
        continue;
      }
      const elementStationOther = this.ordersInAreaStation[j].find((x) => x.orderId === order.orderId);
      if (elementStationOther !== undefined) {
        if (order.workstep.priority) {
          for (let i = elementStationOther.workstep.startSolution - order.workstep.duration + 1; i < 30; i++) {
            this.station[i].children[0].style.backgroundColor = 'grey';
          }
        } else if (elementStationOther.workstep.priority) {
          for (let i = 0; i < elementStationOther.workstep.startSolution + elementStationOther.workstep.duration; i++) {
            this.station[i].children[0].style.backgroundColor = 'grey';
          }
        } else if (elementStationOther.workstep.station === 2) {
          for (let i = elementStationOther.workstep.startSolution; i < 30; i++) {
            this.station[i].children[0].style.backgroundColor = 'grey';
          }
        } else {
          for (let i = 0; i < elementStationOther.workstep.startSolution + elementStationOther.workstep.duration; i++) {
            this.station[i].children[0].style.backgroundColor = 'grey';
          }
        }
      }
    }
  }

  // removed the grey highlighted area after the user is dropped the item
  reset(): void {
    if (this.station !== undefined) {
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.station.length; i++) {
        this.station[i].children[0].style.backgroundColor = '#F5F5F5';
      }
    }
  }

  // Calculate the index of each gantt field
  getClosestRectangle(stationFields): number {
    let insertIndex = 0;
    let minDistance = 99999;
    for (let i = 0; i < stationFields.length; i++) {
      const rectangle = stationFields[i].getBoundingClientRect();
      const calcDistance = Math.sqrt(
        Math.pow(rectangle.left + rectangle.width / 2 - this.xco, 2) +
          Math.pow(rectangle.top + rectangle.height / 2 - this.yco, 2)
      );
      if (calcDistance < minDistance) {
        minDistance = calcDistance;
        insertIndex = i;
      }
    }
    return insertIndex;
  }

  // hovered the field red, if the drop is not possible
  // method is called, if the user drag an item
  handleMovedObject(order: IOrder, isOverlay: boolean): void {
    // reset Style
    for (let z = this.changedStyleIndex; z < this.changedStyleIndex + this.changedStyleTime; z++) {
      if (this.changedStyleStation[z] != null) {
        this.changedStyleStation[z].children[0].style.backgroundColor = '#F5F5F5';
      }
    }
    // highlight just the field if the user is next to the station
    if (!isOverlay) {
      return;
    }
    const station = this.getStationByOrder(order);
    const resultStation: any = document
      .getElementById('btnStation' + station + 'Container')
      .querySelectorAll('div.btn_cell');
    const index = this.getClosestRectangle(resultStation);
    const time = order.workstep.duration;
    const releaseTime = order.releaseDate;
    this.changedStyleTime = time;
    this.changedStyleIndex = index;
    this.changedStyleStation = resultStation;
    for (let i = index; i < index + time; i++) {
      if (resultStation[i] != null) {
        resultStation[i].children[0].style.backgroundColor = order.workstep.color;
        this.ordersInAreaStation[station].filter((x) => {
          if (order.orderId !== x.orderId) {
            if (
              (index >= x.workstep.startSolution && index < x.workstep.startSolution + x.workstep.duration) ||
              (index + time > x.workstep.startSolution &&
                index + time < x.workstep.startSolution + x.workstep.duration) ||
              (index < x.workstep.startSolution && index + time > x.workstep.startSolution + x.workstep.duration) ||
              index + time === x.workstep.startSolution + x.workstep.duration
            ) {
              resultStation[i].children[0].style.backgroundColor = 'red';
            }
          }
        });
      }
    }
    for (let i = index; i < releaseTime; i++) {
      if (resultStation[i] != null) {
        resultStation[i].children[0].style.backgroundColor = 'red';
      }
    }
  }

  // station of drag item
  getStationByOrder(element: IOrder): number {
    for (let i = 0; i < this.ordersInAreaOrders.length; i++) {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < this.ordersInAreaOrders[i].length; j++) {
        if (element === this.ordersInAreaOrders[i][j]) {
          return i;
        }
      }
    }
    return -1;
  }

  dropOrder(event: CdkDragDrop<any[]>, stationIndex): void {
    // i ist die Station
    const btnStation = document
      .getElementById('btnStation' + stationIndex + 'Container')
      .querySelectorAll('div.btn_cell');
    // btnStation -> Nodelist mit 30 Einträgen ( Array mit 30 x div.btn_cell )
    const index = this.getClosestRectangle(btnStation); // gibt an wo das Element im Gantt gedropped wird
    const newX = btnStation[index].getBoundingClientRect().left;
    const newY = btnStation[index].getBoundingClientRect().top;
    const boardElement = document.getElementById('btnStation' + stationIndex + 'Container');
    event.previousContainer.data[event.previousIndex].top = newY - boardElement.getBoundingClientRect().y;
    event.previousContainer.data[event.previousIndex].left = newX - boardElement.getBoundingClientRect().x;

    const element = event.previousContainer.data[event.previousIndex] as IOrder;
    const targetElements = event.container.data as IOrder[];
    const sourceElements = event.previousContainer.data as IOrder[];
    const station = this.getStationByOrder(element);
    // check if the user drop in the right station
    if (station !== stationIndex) {
      this.reset();
      return;
    }
    element.workstep.startSolution = index; // startSolution ist der Ort in dem der Workstepp in Gantt liegt
    // check 'frühester Starttermin'
    if (event.container.id === 'btnStation' + stationIndex + 'Container') {
      if (element.releaseDate > index) {
        element.workstep.isDragDisabled = false;
        this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
        for (let z = this.changedStyleIndex; z < this.changedStyleIndex + this.changedStyleTime; z++) {
          this.changedStyleStation[z].children[0].style.backgroundColor = '#F5F5F5';
        }
        this.reset();
        return;
      }
    }
    // check if the user drop within the gantt-area
    if (event.container.id === 'btnStation' + stationIndex + 'Container') {
      if (element.workstep.startSolution + element.workstep.duration > 30) {
        element.workstep.isDragDisabled = false;
        this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
        for (let z = this.changedStyleIndex; z < this.changedStyleIndex + this.changedStyleTime; z++) {
          this.changedStyleStation[z].children[0].style.backgroundColor = '#F5F5F5';
        }
        this.reset();
        return;
      }
    }
    // check if two orders eclipsed
    if (event.container.id === 'btnStation' + stationIndex + 'Container') {
      // tslint:disable-next-line:prefer-for-of
      for (let j = 0; j < event.container.data.length; j++) {
        const tmpElement: IOrder = event.container.data[j];
        if (tmpElement !== element) {
          if (
            (element.workstep.startSolution >= tmpElement.workstep.startSolution &&
              element.workstep.startSolution < tmpElement.workstep.startSolution + tmpElement.workstep.duration) ||
            (element.workstep.startSolution + element.workstep.duration > tmpElement.workstep.startSolution &&
              element.workstep.startSolution + element.workstep.duration <
                tmpElement.workstep.startSolution + tmpElement.workstep.duration) ||
            (element.workstep.startSolution < tmpElement.workstep.startSolution &&
              element.workstep.startSolution + element.workstep.duration >
                tmpElement.workstep.startSolution + tmpElement.workstep.duration) ||
            element.workstep.startSolution + element.workstep.duration ===
              tmpElement.workstep.startSolution + tmpElement.workstep.duration
          ) {
            element.workstep.isDragDisabled = false;
            this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
            for (let z = this.changedStyleIndex; z <= this.changedStyleIndex + this.changedStyleTime; z++) {
              this.changedStyleStation[z].children[0].style.backgroundColor = '#CCCCFF';
            }
            this.reset();
            return;
          }
        }
      }
    }
    // check the priority rules
    if (event.container.id === 'btnStation' + stationIndex + 'Container') {
      for (let j = 0; j < this.ordersInAreaStation.length; j++) {
        if (j === station) {
          continue;
        }
        const orderFromStationTmp = this.ordersInAreaStation[j].find((x) => element.orderId === x.orderId);
        if (orderFromStationTmp !== undefined) {
          if (orderFromStationTmp.workstep.priority) {
            if (
              orderFromStationTmp.workstep.startSolution + orderFromStationTmp.workstep.duration >
              element.workstep.startSolution
            ) {
              element.workstep.isDragDisabled = false;
              this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
              this.reset();
              return;
            }
          } else if (element.workstep.priority) {
            if (
              element.workstep.startSolution + element.workstep.duration >
              orderFromStationTmp.workstep.startSolution
            ) {
              element.workstep.isDragDisabled = false;
              this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
              this.reset();
              return;
            }
          } else if (element.workstep.station === 1) {
            if (
              orderFromStationTmp.workstep.startSolution <
              element.workstep.startSolution + element.workstep.duration
            ) {
              element.workstep.isDragDisabled = false;
              this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
              this.reset();
              return;
            }
          } else if (
            element.workstep.startSolution <
            orderFromStationTmp.workstep.startSolution + orderFromStationTmp.workstep.duration
          ) {
            element.workstep.isDragDisabled = false;
            this.ordersInAreaStation[stationIndex] = targetElements.filter((x) => x.orderId !== element.orderId);
            this.reset();
            return;
          }
        }
      }
    }
    if (event.previousContainer !== event.container) {
      element.workstep.isDragDisabled = true;
      const targetElement = targetElements.find((x) => x.orderId === element.orderId);
      // das Element wird zurueckgeschoben in die OrderArea
      if (targetElement) {
        targetElement.workstep.isDragDisabled = false;
        this.ordersInAreaStation[stationIndex] = sourceElements.filter((x) => x.orderId !== element.orderId);
        for (let z = this.changedStyleIndex; z < this.changedStyleIndex + this.changedStyleTime; z++) {
          this.changedStyleStation[z].children[0].style.backgroundColor = '#F5F5F5';
        }
        element.workstep.startSolution = -1;
        this.reset();
        return;
      }
      copyArrayItem(event.previousContainer.data, event.container.data, event.previousIndex, event.currentIndex);
    } else {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }
    this.reset();
    // console.log('task -> this.ordersInAreaStation ', this.ordersInAreaStation);
  }

  displayPermutationsInGantt(): void {
    let resultStation;
    for (let i = 0; i < this.stationCount; i++) {
      resultStation = document.getElementById('btnStation' + i + 'Container').querySelectorAll('div.btn_cell');
      for (let num = 0; num < 220; num++) {
        resultStation[num].children[0].style.borderRight = 'solid 1px';
        resultStation[num].children[0].style.borderRightColor = '#c0c0c0';
        resultStation[num].children[0].innerText = ' ';
      }
    }
    for (let i = 0; i < this.stationCount; i++) {
      resultStation = document.getElementById('btnStation' + i + 'Container').querySelectorAll('div.btn_cell');
      for (let j = 0; j < this.workstepsInGantt[i].length; j++) {
        let index;
        if (this.workstepsInGantt[i][j].duration % 2 === 0) {
          index = this.workstepsInGantt[i][j].startSolution - 1 + this.workstepsInGantt[i][j].duration / 2;
        } else {
          index = this.workstepsInGantt[i][j].startSolution - 2 + (this.workstepsInGantt[i][j].duration + 1) / 2;
        }
        resultStation[index].children[0].innerText = 'A';
        let difference;
        if (this.workstepsInGantt[i][j].orderNumber >= 10 && this.workstepsInGantt[i][j].orderNumber < 20) {
          difference = this.workstepsInGantt[i][j].orderNumber - 10;
          resultStation[index + 1].children[0].innerText = 1;
          resultStation[index + 2].children[0].innerText = difference;
        } else {
          resultStation[index + 1].children[0].innerText = this.workstepsInGantt[i][j].orderNumber;
        }
        resultStation[index].children[0].style.fontSize = 'small';
        resultStation[index].children[0].style.display = 'flex';
        resultStation[index].children[0].style.alignItems = 'center';
        resultStation[index].children[0].style.justifyContent = 'center';
        resultStation[index + 1].children[0].style.fontSize = 'small';
        resultStation[index + 1].children[0].style.display = 'flex';
        resultStation[index + 1].children[0].style.alignItems = 'center';
        resultStation[index + 1].children[0].style.justifyContent = 'center';
        resultStation[index + 2].children[0].style.fontSize = 'small';
        resultStation[index + 2].children[0].style.display = 'flex';
        resultStation[index + 2].children[0].style.alignItems = 'center';
        resultStation[index + 2].children[0].style.justifyContent = 'center';
        const start = this.workstepsInGantt[i][j].startSolution;
        const end = this.workstepsInGantt[i][j].startSolution + this.workstepsInGantt[i][j].duration - 1;
        let k = start;
        let l = end;
        while (k < end) {
          resultStation[k].children[0].style.borderRight = 'none';
          resultStation[l].children[0].style.borderLeft = 'none';
          k++;
          l--;
        }
        resultStation[start - 1].children[0].style.borderRight = 'solid 2px';
        resultStation[start - 1].children[0].style.borderRightColor = 'black';
        resultStation[end].children[0].style.borderRight = 'solid 2px';
        resultStation[end].children[0].style.borderRightColor = 'black';
      }
    }
  }

  back(goBack): void {
    this.modalService.open(goBack);
  }

  openExplanation(help): void {
    this.dialog.open(help);
  }

  checkIfAllOrdersInStation(): void {
    this.allOrdersInStation = true;
    for (let i = 0; i < this.stationCount; i++) {
      for (const order of this.ordersInAreaOrders[i]) {
        if (order.workstep.duration === 0) {
          this.numberOfOrders = this.numberOfOrders - 1;
        }
      }
      if (this.ordersInAreaStation[i].length < this.numberOfOrders) {
        this.allOrdersInStation = false;
        this.numberOfOrders = this.task.numberOfOrders;

        break; // No need to check other stations, as the current station is not fully completed...
      }
      this.numberOfOrders = this.task.numberOfOrders;
    }
  }

  // check which timeLabel should be displayed
  correctTimeLabel(): void {
    switch (this.task.timeLabel) {
      case 'STUNDEN':
        this.timeLabel = 'Stunden';
        break;
      case 'MINUTEN':
        this.timeLabel = 'Minuten';
        break;
      default:
        break;
    }
  }

  async next(content): Promise<void> {
    this.checkIfAllOrdersInStation();
    if (!this.allOrdersInStation) {
      this.modalService.open(content);
    } else {
      this.allOrdersInStation = true;
      this.goToSolution();
    }
  }

  // if the user press 'zurücksetzen'
  resetPage(): void {
    window.location.reload();
  }

  goBackToDashboard(): void {
    this.router.navigate(['/dashboard/1']);
  }

  async goToSolution(): Promise<void> {
    // change the format of IOrder to ITask
    const taskSolution = this.dataFormatService.getTaskUserSolution(
      this.task,
      this.ordersInAreaStation,
      this.exponatType,
      false
    );
    // post-request, if all order are in station
    if (this.allOrdersInStation) {
      this.keyFigure = await this.dataService.postTask(taskSolution);
      // save the response (calculated Keyfigures) in the local-storage
      window.sessionStorage.setItem('keyFigure', JSON.stringify(this.keyFigure));
      // save all datas of the actual task in local-storage
    }
    sessionStorage.setItem('datas', JSON.stringify(this.ordersInAreaStation));

    this.router.navigate(['1/solution', this.currentTask]);
  }

  clearStartSolution(): void {
    for (const order of this.task.orderList) {
      for (const workstep of order.workstepList) {
        workstep.startSolution = 0;
      }
    }
  }
}
