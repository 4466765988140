import { Component, Input } from '@angular/core';

import { IOrder } from '../../../Interfaces/IOrder';
import { ITask } from '../../../Interfaces/ITask';

@Component({
  selector: 'app-exponat-ressourcenbelegungsplanung-solution-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
})
export class SolutionOrderComponent {
  @Input() orders: IOrder[][];
  @Input() task: ITask;
  @Input() stationCount: number;
}
