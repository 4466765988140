<div class="containerOrder">
  <div *ngFor="let col of [].constructor(this.stationCount); let j = index">
    <span class="station-name">{{ task.descOfStationList[j] }} </span>

    <input
      *ngIf="task.stationPictureList"
      class="icon"
      type="image"
      src="../../../../assets/general/icon.PNG"
      alt="icon"
      (click)="showPicture(pictureOrder)"
    />
    <ng-template #pictureOrder let-modal>
      <div class="modal-header">
        <button type="button" class="close" aria-label="Close" (click)="modal.close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-content">
        <h3>{{ task.descOfStationList[j] }}:</h3>
        <img class="pictureStation" src="../../../assets/task/{{ task.stationPictureList[j] }}" alt="" />
        <h3></h3>
      </div>
    </ng-template>

    <hr style="margin: 0" />
    <div
      class="order-list"
      cdkDropList
      [cdkDropListData]="this.ordersInAreaOrders[j]"
      (cdkDropListDropped)="drop($event, j)"
      cdkDropListSortingDisabled="true"
    >
      <ng-container *ngFor="let order of ordersInAreaOrders[j]; let i = index">
        <! -- If duration of workstep is 0 order isn't displayed -->
        <div class="order-box" [style.visibility]="order.workstep.duration !== 0 ? 'visible' : 'hidden'">
          <p
            class="name"
            *ngIf="order.workstep.priority"
            style="color: red"
            triggers="click"
            [autoClose]="'outside'"
            ngbTooltip="Priorisierter Auftrag"
          >
            Auftrag {{ i + 1 }}
          </p>
          <p class="name" *ngIf="!order.workstep.priority">Auftrag {{ i + 1 }}</p>
          <div
            class="order-status"
            [ngStyle]="{ 'background-color': order.workstep.isDragDisabled ? order.workstep.color : 'white' }"
          ></div>

          <div
            class="order-information"
            [cdkDragData]="order"
            [cdkDragDisabled]="order.workstep.isDragDisabled"
            [ngStyle]="{ 'background-color': order.workstep.isDragDisabled ? '#CCCCCC' : order.workstep.color }"
            (cdkDragMoved)="moved({ event: $event, order: order, index: j })"
            cdkDrag
          >
            <ng-template #tooltipList>
              <div style="text-align: left" class="d-flex flex-column">
                <span>t: Bearbeitungszeit</span>
                <span>a: frühester Starttermin</span>
                <span>f: Soll-Endtermin</span>
              </div>
            </ng-template>
            <div class="order-tooltip" triggers="click" [autoClose]="'outside'" [ngbTooltip]="tooltipList">
              <div *cdkDragPlaceholder></div>
              <ul style="margin: 0; padding: 0; list-style: none">
                <li *ngIf="!order.workstep.priority">t: {{ order.workstep.duration * task.timeFactor }}</li>
                <li *ngIf="order.workstep.priority">t: {{ order.workstep.duration * task.timeFactor }} *</li>
                <li *ngIf="order.releaseDate !== -1">a: {{ order.releaseDate * task.timeFactor }}</li>
                <li *ngIf="order.targetDate !== -1">f: {{ order.targetDate * task.timeFactor }}</li>
              </ul>
            </div>
          </div>
        </div>
      </ng-container>
      <hr />
    </div>
  </div>
</div>
