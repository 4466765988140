import { Component, Input } from '@angular/core';

import { IKeyfigure } from '../../../Interfaces/IKeyfigure';

@Component({
  selector: 'app-values-solution',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.css'],
})
export class SolutionValuesComponent {
  @Input() keyFigure: IKeyfigure;
  @Input() timeFactor: number;
  @Input() targetCriterion: string;
  @Input() timeLabel: string;
  @Input() exponatType: string;
}
