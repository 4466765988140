import {Component, Input} from '@angular/core';

import {IKeyfigure} from '../../../Interfaces/IKeyfigure';

@Component({
  selector: 'app-values-task',
  templateUrl: './values.component.html',
  styleUrls: ['./values.component.css'],
})
export class TaskValuesComponent {
  @Input() keyFigure: IKeyfigure;
  @Input() timeFactor: number;
  @Input() targetCriterion: string;
  @Input() timeLabel: string;
}
