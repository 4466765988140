<mat-spinner class="spinner" *ngIf="isLoading"></mat-spinner>
<ng-container *ngIf="task">
  <mat-card style="margin: 2%">
    <mat-card-content class="content-card">
      {{ task.description }}
      <div *ngIf="task.targetCriterion === 'MIN_TOTALTIME'">
        <p style="font-weight: bold">Ziel: Kürzeste Gesamtbearbeitungszeit</p>
      </div>
      <div *ngIf="task.targetCriterion === 'MIN_VMAX'">
        <p style="font-weight: bold">Ziel: Minimierung der maximalen Verspätung</p>
      </div>
      <div *ngIf="task.targetCriterion === 'MIN_VSUM'">
        <p style="font-weight: bold">Ziel: Minimierung der Summe der Verspätungen</p>
      </div>
      <div *ngIf="task.targetCriterion === 'MIN_VMID'">
        <p style="font-weight: bold">Ziel: Minimierung der mittleren Verspätung</p>
      </div>
      <div *ngIf="task.targetCriterion === 'MIN_VVAR'">
        <p style="font-weight: bold">Ziel: Minimierung der Streuung (Varianz)</p>
      </div>
    </mat-card-content>
  </mat-card>

  <div class="containerGrid">
    <div cdkDropListGroup>
      <div>
        <app-exponat-ressourcenbelegungsplanung-task-order
          [task]="task"
          [stationCount]="stationCount"
          [ordersInAreaOrders]="ordersInAreaOrders"
          (changePos)="mousePos($event)"
          (dropItem)="dropTmp($event)"
        >
        </app-exponat-ressourcenbelegungsplanung-task-order>
        <app-gantt-task
          [task]="task"
          [stations]="stations"
          [stationCount]="stationCount"
          [ordersInAreaStation]="ordersInAreaStation"
          [exponatType]="exponatType"
          (changePos)="mousePos($event)"
          (dropItem)="dropTmp($event)"
        >
        </app-gantt-task>
      </div>
    </div>
  </div>

  <div class="buttons" style="padding-bottom: 2%">
    <button id="back" class="btn-check btn btn-dark" (click)="back(goBack)">Zurück</button>
    <button id="help" style="margin-left: 2%" class="btn-check btn btn-secondary" (click)="openExplanation(help)">
      Hilfe
    </button>
    <button id="reset" style="margin-left: 2%" class="btn-check btn btn-secondary" (click)="resetPage()">
      Zurücksetzen
    </button>
    <button id="next" style="margin-left: 2%" class="btn-check btn btn-primary" (click)="next(content)">Weiter</button>
  </div>

  <ng-template #help>
    <div>
      <p class="explanation">
        Für jeden Auftrag gibt es ein Kästchen. Die Bearbeitungszeit des Auftrags wird Ihnen im zugehörigen Kästchen in
        einer Tabelle angegeben. Dort sehen Sie gegebenenfalls auch den frühesten Starttermin und den Soll-Endtermin für
        den jeweiligen Auftrag. Sobald Sie einen Auftrag in das Diagramm ziehen wollen, wird Ihnen die Bearbeitungszeit
        zusätzlich durch die Länge des Kästchens angezeigt. Durch einen Klick auf den Auftrag, erscheint ein Tooltip mit
        Informationen zum Auftrag.
        <br/>
        <br/>
        Gesamtbearbeitungszeit: Spätester Fertigstellungstermin; mit anderen Worten frühester Zeitpunkt an dem alle Aufträge abgeschlossen sind.
      </p>
        <span style="font-weight: bold;">Hinweis: </span>
        <ul>
          <li>
            Die erste Operation eines Auftrags darf beliebig eingeplant werden.
          </li>
          <li>
            Die zweite Operation eines Auftrags darf erst nach der Beendigung der ersten Operation eines Auftrags eingeplant werden.
          </li>
        </ul>
      <img class="explanation-gif" src="../src/assets/explanation.gif" />
    </div>
  </ng-template>

  <ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Sind Sie sicher?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Sie haben noch nicht alle Operationen verteilt, wollen Sie die Seite wirklich verlassen?</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close()">Weiter versuchen</button>
      <button type="button" class="btn btn-dark" (click)="modal.close(); goToSolution()">Zur Lösung</button>
    </div>
  </ng-template>

  <ng-template #goBack let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-go-back">Wollen Sie zurück zur Startseite?</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Ihr aktueller Stand wird nicht gespeichert!</p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="modal.close(); goBackToDashboard()">Ja</button>
      <button type="button" class="btn btn-dark" (click)="modal.close()">Nein</button>
    </div>
  </ng-template>
  <div></div>
</ng-container>
