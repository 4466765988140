import {Location} from '@angular/common';
import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DataService} from '../../Interfaces/data.service';
import {ITask} from '../../Interfaces/ITask';

@Component({
  selector: 'app-task-introduction',
  templateUrl: './task-introduction.component.html',
  styleUrls: ['./task-introduction.component.css']
})
export class TaskIntroductionComponent implements OnInit {

  currentTask: string;
  task: ITask;
  exponatType: string;
  caseStudyType: string;

  constructor(private router: Router,
              private dataService: DataService,
              private route: ActivatedRoute,
              private location: Location) {
    // check which task is currently displayed. And saved the taskId in currentTask
    this.route.params.subscribe(params => this.currentTask = params.id);
    // receive the exponatType from local-storage
    this.exponatType = JSON.parse(window.sessionStorage.getItem('exponatType'));
    // receive the caseStudyType
    this.caseStudyType = JSON.parse(window.sessionStorage.getItem('caseStudyType'));
    console.log("Exponattyp: "+this.exponatType);
    console.log("Typ Fallstudie: " + this.caseStudyType);
  }

  async ngOnInit(): Promise <void> {
   // reveive the data of the currentTask
   this.task = await this.dataService.getTask(this.currentTask);
  }
  // method is called after pressing 'weiter' Button
  next(): void {
    if (this.exponatType === "EXPONAT1"){
      this.router.navigate(['1/task', this.currentTask]);
    }
    else if (this.caseStudyType === "PERMUTATION"){
      this.router.navigate(['2-2/task', this.currentTask]);
    }
    else if (this.exponatType === "EXPONAT2"){
      this.router.navigate(['2/task', this.currentTask]);
    }
  }
  // method is called after pressing 'zurück' Button
  goBack(): void {
      this.router.navigate(['/dashboard/1']);
  }
}
