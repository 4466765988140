import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ICaseStudy} from './ICaseStudy';
import {IKeyfigure} from './IKeyfigure';
import {IOrder} from './IOrder';
import {ITask} from './ITask';

@Injectable()
export class DataService {
  private port = '';
  // private hostname = 'http://localhost';       // Use for local testing
  private hostname = `https://im-vm-050.hs-regensburg.de/api`;
  // private hostname = 'raspberrypi';   // Use for Raspberry Pi
  // private hostname = '194.95.108.188';   // Use for virtual machine
  private permuationHardwareAvailable = false;


  private taskUrl = `${this.hostname}${this.port}/task`;
  private caseStudyUrlExhibit1 = `${this.hostname}${this.port}/casestudies?exponatType=exponat1`;
  private caseStudyUrlExhibit2 = `${this.hostname}${this.port}/casestudies?exponatType=exponat2`;
  private caseStudyPhysicalUrl = `${this.hostname}${this.port}/casestudies?physical=true`;
  private keyFiguresUrl = `${this.hostname}${this.port}/keyfigures`;
  private currentPhysicalSolutionUrl = `http://${this.hostname}:4100/currentTask/physicalSolution`;
  private permutationsUrl = `${this.hostname}${this.port}/permutations`;

  constructor(private httpClient: HttpClient) {
  }

  async getTask(taskId: string): Promise<ITask> {
    return await this.httpClient.get<ITask>(`${this.taskUrl}/${taskId}`).toPromise();
  }

  async getCurrentPhysicalSolutionTask(taskId: string): Promise<ITask> {
    // If no physical backend is available use default backend and display solution at task page
    // tslint:disable-next-line:triple-equals
    if (this.hostname != 'raspberrypi') {
      return await this.httpClient.get<ITask>(`${this.taskUrl}/${taskId}`).toPromise();
    } else {
      return await this.httpClient.get<ITask>(`${this.currentPhysicalSolutionUrl}/${taskId}`).toPromise();
    }
  }

  getCaseStudy(exhibitId: string): Observable<ICaseStudy[]> {
    if (exhibitId === '1' || exhibitId.toUpperCase() === 'EXPONAT1') {
      console.log(this.caseStudyUrlExhibit1);
      return this.httpClient.get<ICaseStudy[]>(this.caseStudyUrlExhibit1);
    } else if (exhibitId === '2' || exhibitId.toUpperCase() === 'EXPONAT2') {
      console.log(this.caseStudyUrlExhibit2);
      return this.httpClient.get<ICaseStudy[]>(this.caseStudyUrlExhibit2);
    }
  }

  getPhysicalCaseStudy(): Observable<ICaseStudy[]> {
    return this.httpClient.get<ICaseStudy[]>(this.caseStudyPhysicalUrl);
  }

  async postTask(task: ITask): Promise<IKeyfigure> {
    return await this.httpClient.post(this.keyFiguresUrl, task).toPromise();
  }


  async postPermutations(listOfOrders: IOrder[], taskId: string): Promise<ITask> {
    return await this.httpClient.post<ITask>(`${this.permutationsUrl}/${taskId}`, listOfOrders).toPromise();
  }

}
