import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {DataService} from '../../Interfaces/data.service';
import {ICaseStudy} from '../../Interfaces/ICaseStudy';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  casestudies: ICaseStudy[] = [];
  selectedExhibit: string;

  constructor(private router: Router,
              private dataService: DataService,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.selectedExhibit = params.id);
  }

  ngOnInit(): void {
    // receive casestudies and save them in the array casestudies
    if (this.selectedExhibit !== '0'){
      this.dataService.getCaseStudy(this.selectedExhibit).subscribe(casestudies => this.casestudies = casestudies);
    }
  }
  // method is called by pressing the 'starten'-Button
  nextPage(casestudy: ICaseStudy): void {
    // save the taskIds of the selected casestudy in the local-storage
    window.sessionStorage.setItem('taskIdList', JSON.stringify(casestudy.taskIdList));
    // save the casestudydescription of the selected casestudy in the local-storage
    window.sessionStorage.setItem('caseStudyDescription', JSON.stringify(casestudy.description));
    // save the exponatType in the local-storage
    window.sessionStorage.setItem('exponatType', JSON.stringify(casestudy.exponatType));
    // save the caseStudyType
    window.sessionStorage.setItem('caseStudyType', JSON.stringify(casestudy.caseStudyType));
    // navigation to the next page
    this.router.navigate(['/caseStudyInfo']);
  }
}
