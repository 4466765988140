import {HttpClientModule} from '@angular/common/http';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

// App-Module
import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {DashboardModule} from './dashboard-module/dashboard.module';
// Module der einzelnen Exponate
import {ExponatRessourcenbelegungsplanungModule} from './exponatRessourcenbelegungsplanung/exponatRessourcenbelegungsplanung.module';
// Exponatübergreifende Module
import {InformationModule} from './information-module/information.module';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ExponatRessourcenbelegungsplanungModule,
    NgbModule,
    HttpClientModule,
    InformationModule,
    DashboardModule,
    ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
