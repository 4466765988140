import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {IOrder} from '../../../Interfaces/IOrder';
import {ITask} from '../../../Interfaces/ITask';

@Component({
  selector: 'app-exponat-ressourcenbelegungsplanung-task-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.css'],
})
export class TaskOrderComponent {
  @Output() changePos = new EventEmitter();
  @Output() dropItem = new EventEmitter();
  @Input() workstepId: number;
  @Input() stationCount: number;
  @Input() ordersInAreaOrders: IOrder[][];
  @Input() task: ITask;
  @Input() value: string;
  x = 0;
  y = 0;
  constructor(private modalService: NgbModal) {}

  moved(param): void {
    const obj = param.event;
    const order = param.order;
    const i = param.index;
    this.x = obj.pointerPosition.x;
    this.y = obj.pointerPosition.y;
    this.changePos.emit({x: this.x, y: this.y, order: order, index: i});
  }
  drop($event, i): void {
    this.dropItem.emit({event: $event, i: i});
  }
  showPicture(pictureOrder): void {
    this.modalService.open(pictureOrder);
  }
}
