<div class="gantt-box">
  <div>
    <div *ngFor="let station of [].constructor(this.stationCount); let st = index">
      <div class="station-name" style="margin: 0">{{ task.descOfStationList[st] }}</div>
      <div
        id="btnStation{{ st }}Container"
        class="container1"
        cdkDropList
        [cdkDropListData]="this.ordersInAreaStation[st]"
        (cdkDropListDropped)="drop($event, st)"
        style="position: relative"
      >
        <ng-container *ngFor="let orderInStation1 of this.ordersInAreaStation[st]">
          <ng-template #tooltipList>
            <div style="text-align: left" class="d-flex flex-column">
              <span>Auftr.: {{ orderInStation1.workstep.orderNumber }} </span>
              <span>t: {{ orderInStation1.workstep.duration * task.timeFactor }}</span>
            </div>
          </ng-template>

          <div
            class="line"
            [style.backgroundColor]="orderInStation1.workstep.color"
            style="position: absolute"
            [style.top]="orderInStation1.top + 'px'"
            [style.left]="orderInStation1.left + 'px'"
          >
            <div
              class="order-box order-tooltip"
              triggers="click"
              [autoClose]="'inside'"
              [ngbTooltip]="tooltipList"
              (cdkDragMoved)="moved({ event: $event, order: orderInStation1, index: st })"
              cdkDragLockAxis="x"
              cdkDrag
            >
              <div *cdkDragPlaceholder></div>
              <div
                class="order-box-cell"
                *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, orderInStation1.workstep.duration)"
              ></div>
            </div>
          </div>
        </ng-container>

        <div class="btn_cell" *ngFor="let row of this.stations[st]; index as i">
          <div class="cell"></div>
          <p class="timeline">{{ i * task.timeFactor }}</p>
        </div>
        <div class="btn_cell">
          <div class="CellNoBorder"></div>
          <div *ngIf="task.timeLabel === 'MINUTEN'">
            <p class="timeline" style="font-weight: bold; text-align: center">Minuten</p>
          </div>
          <div *ngIf="task.timeLabel === 'STUNDEN'">
            <p class="timeline" style="font-weight: bold; text-align: center">Stunden</p>
          </div>
          <div *ngIf="task.timeLabel === 'ZE'">
            <p class="timeline" style="font-weight: bold; text-align: center">ZE</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <br /><br />
</div>
<hr />
