import {Component, EventEmitter, Input, Output} from '@angular/core';

import {IOrder} from '../../../Interfaces/IOrder';
import {ITask} from '../../../Interfaces/ITask';
import {IWorkstep} from '../../../Interfaces/IWorkstep';

@Component({
  selector: 'app-gantt-task',
  templateUrl: './gantt.component.html',
  styleUrls: ['./gantt.component.css'],
})
export class TaskGanttComponent {
  @Output() changePos = new EventEmitter();
  @Output() dropItem = new EventEmitter();
  @Input() ordersInAreaStation: IOrder[][];
  @Input() workstepInAreaStation: IWorkstep[][];
  @Input() maxNumberWorksteps: number;
  @Input() exponatType: string;
  @Input() stationCount: number;
  @Input() stations: number[][];
  @Input() task: ITask;
  @Input() caseStudyType: string;

  x = 0;
  y = 0;
  numbers: number[] = [25, 50, 75, 100, 125, 150, 175, 200];

  // Event-Binding; get position of moved item
  // Benötigt hieraus nur die Koordinaten x und y für das Verschieben der Order im Gantt
  moved(param): void {
    const obj = param.event;
    const order = param.order;
    const index = param.index;
    this.x = obj.pointerPosition.x;
    this.y = obj.pointerPosition.y;
    console.log('gantt- -> moved -> x:', this.x, 'y', this.y, 'order', order, 'index', index);
    this.changePos.emit({x: this.x, y: this.y, order, index});
  }

  // Event-Binding; drop item
  drop($event, i): void {
    console.log('gantt -> drop -> $event:', $event, '### i ###', i);
    this.dropItem.emit({event: $event, i});
  }
}
