import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

import {DataService} from '../../Interfaces/data.service';
import {ICaseStudy} from '../../Interfaces/ICaseStudy';
import {ITask} from '../../Interfaces/ITask';

@Component({
  selector: 'app-case-study-info',
  templateUrl: './case-study-info.component.html',
  styleUrls: ['./case-study-info.component.css']
})
export class CaseStudyInfoComponent implements OnInit {
  caseStudy: ICaseStudy;
  currentCaseStudy: string;
  caseStudyDescription: string;
  taskList;
  firstTask: ITask;
  exponatType: string;
  caseStudyType: string;


  constructor(private router: Router,
              private dataService: DataService) {
  }

  async ngOnInit(): Promise <void> {
    // receive the taskList with all taskIds for the selected casestudy from local-storage
    this.taskList = JSON.parse(sessionStorage.getItem('taskIdList'));
    // receive the casestudyDescription for the selected casestudy from local-storage
    this.caseStudyDescription = JSON.parse(sessionStorage.getItem('caseStudyDescription'));
    // receive the firstTask of the casestudy
    this.firstTask = await this.dataService.getTask(this.taskList[0]);
    // receive the exponatType from local-storage
    this.exponatType = JSON.parse(window.sessionStorage.getItem('exponatType'));
    this.caseStudyType = JSON.parse(window.sessionStorage.getItem('caseStudyType'));
}

  // method is called by pressing the 'Weiter' Button
  next(): void {
    // checked if the task has a introduction
    if (this.caseStudyType === "RANDOMWALK"){
      this.router.navigate(['2-3/task', this.taskList[0]]);
      return;
    }
    if (this.taskList[0].introduction == "") {
      if (this.exponatType === "EXPONAT1"){
        this.router.navigate(['1/task', this.taskList[0]]);
      }
    } else {
       if (this.caseStudyType === 'PERMUTATION'){
         this.router.navigate(['2-2/task', this.taskList[0]]);
       }else
      // if there is an introduction available, the page navigates to task-introduction
      this.router.navigate(['task-introduction', this.taskList[0]]);
    }
  }
  // method is called by pressing the 'Zurück' Button
  goBack(): void {
    if (this.router.url.startsWith('/physical')) {
      this.router.navigate(['/physical/dashboard']);
    } else {
        this.router.navigate(['/dashboard/1']);
    }
  }
}
