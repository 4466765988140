<ng-container *ngIf="task">
  <mat-card  style="text-align: center">
    <mat-card-content class="text">{{task.introduction}}</mat-card-content>

    <div *ngIf="!task.introductionVideo == ''">
      <video width="640" height="360" controls controlsList="nodownload">
        <source src="../../../assets/videos/{{task.introductionVideo}}" type="video/mp4">
        Your browser does not support the video tag.
      </video>
      <br>
      <p style="color:grey">
        Die Nutzung des Videos erfolgt mit freundlicher Genehmigung des <strong>SWR</strong>.
      </p>
    </div>

  </mat-card>

<div class="buttons">
  <button class="btn-check btn btn-dark" (click)="goBack()">Zurück</button>
  <button class="btn-check btn btn-primary" style="margin-left: 2%" (click)="next()">Weiter</button>
</div>
</ng-container>
