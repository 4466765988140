<mat-card style="width: 100%; margin-left: 2%">
  <mat-card-content class="content-card">
    <div>
      <table>
        <tr *ngIf="targetCriterion === 'MIN_TOTALTIME'">
          <th class="target">Gesamtbearbeitungszeit:</th>
          <td class="target">{{ keyFigure.totalProcessingTime * timeFactor }} {{ timeLabel }}</td>
        </tr>
        <tr *ngIf="targetCriterion === 'MIN_VMAX'">
          <th class="target">Maximale Verspätung (vMax):</th>
          <td class="target">{{ keyFigure.vMax * timeFactor }} {{ timeLabel }}</td>
        </tr>
        <tr *ngIf="targetCriterion === 'MIN_VSUM'">
          <th class="target">Summe der Verspätungen (vSum):</th>
          <td class="target">{{ keyFigure.vSum * timeFactor }} {{ timeLabel }}</td>
        </tr>
      </table>
    </div>
  </mat-card-content>
</mat-card>
