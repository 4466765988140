<div class="container-solution-order">
  <div *ngFor="let col of [].constructor(this.stationCount); let j = index">
    <span class="station-name">{{ task.descOfStationList[j] }}</span>
    <hr style="margin: 0" />
    <ng-container *ngFor="let order of orders[j]; let i = index">
      <!--Worksteps with duration 0 aren't displayed -->
      <div class="order-box" [style.visibility]="order.workstep.duration !== 0 ? 'visible' : 'hidden'">
        <p id="name">Auftrag {{ i + 1 }}</p>
        <div class="order-status" [ngStyle]="{ 'background-color': order.workstep.color }">
          <ng-template #tooltipList>
            <div style="text-align: left" class="d-flex flex-column">
              <span>t: Bearbeitungszeit</span>
              <span>a: frühester Starttermin</span>
              <span>f: Soll-Endtermin</span>
            </div>
          </ng-template>
          <div class="order-tooltip" triggers="click" [autoClose]="'outside'" [ngbTooltip]="tooltipList"></div>
        </div>
        <div class="order-information" [ngStyle]="{ 'background-color': '#C0C0C0' }">
          <ul style="margin: 0; padding: 0; list-style: none">
            <li *ngIf="!order.workstep.priority">t: {{ order.workstep.duration * task.timeFactor }}</li>
            <li *ngIf="order.workstep.priority">t: {{ order.workstep.duration * task.timeFactor }} *</li>
            <li *ngIf="order.releaseDate !== -1">a: {{ order.releaseDate * task.timeFactor }}</li>
            <li *ngIf="order.targetDate !== -1">f: {{ order.targetDate * task.timeFactor }}</li>
          </ul>
        </div>
      </div>
    </ng-container>
  </div>
</div>
