import { Component, OnInit } from '@angular/core';
import { AfterViewChecked } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { DataService } from '../../../Interfaces/data.service';
import { FormatDataService } from '../../../Interfaces/format-data.service';
import { IKeyfigure } from '../../../Interfaces/IKeyfigure';
import { IOrder } from '../../../Interfaces/IOrder';
import { ITask } from '../../../Interfaces/ITask';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-exponat-ressourcenbelegungsplanung-solution-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.css'],
})
export class ExponatRessourcenbelegungsplanungSolutionContainerComponent implements OnInit, AfterViewChecked {
  isLoading: boolean;
  allOrdersInStation: boolean;
  keyFigureValid: boolean;
  targetAchieved: boolean;
  differenceToTarget: number;
  ordersInAreaOrders: IOrder[][] = [];
  solutionUser: IOrder[][] = [];
  task: ITask;
  stationCount;
  keyFigureOptimal: IKeyfigure;
  keyFigureUser: IKeyfigure;
  stations: number[][] = [];
  currentTask: string;
  taskList: string[];
  currentCaseStudy: string;
  caseStudyIdList: string[];
  nextTask: ITask;
  numberOfOrders: number;
  timeLabel: string;
  exponatType: string;
  targetCriterion: string;

  patterns: string[];
  buttonIdClickedBefore: string;

  constructor(
    private dataService: DataService,
    private dataFormatService: FormatDataService,
    private router: Router,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    public dialog: MatDialog,
  ) {
    // check which task is currently displayed. And saved the taskId in currentTask
    this.route.params.subscribe((params) => (this.currentTask = params.id));
  }

  async ngOnInit(): Promise<void> {
    // receive the currentTask and saved them in task
    this.task = await this.dataService.getTask(this.currentTask);
    // set targetCriterion so it can be modified later
    this.targetCriterion = this.task.targetCriterion;
    // get just the keyFigure data of the received task
    this.keyFigureOptimal = this.dataFormatService.getKeyFigureData(this.task);
    // receive the exponatType from local-storage
    this.exponatType = JSON.parse(window.sessionStorage.getItem('exponatType'));
    // receive the saved start-values of the user
    this.solutionUser = JSON.parse(sessionStorage.getItem('datas'));
    // receive the keyFigures the backend was sending after post-request in the task-component
    this.keyFigureUser = JSON.parse(sessionStorage.getItem('keyFigure'));
    // receive the list of all taskIds from the selected casestudy
    this.taskList = JSON.parse(window.sessionStorage.getItem('taskIdList'));
    // save the number of stations
    this.stationCount = this.task.numberOfStations;
    // save the number of orders
    this.numberOfOrders = this.task.numberOfOrders;
    // create a gantt-diagramm
    for (let i = 1; i <= this.stationCount; i++) {
      const ordersInStation = this.dataFormatService.getIOrderData(this.task, i, '0');
      this.ordersInAreaOrders.push(ordersInStation);
      const station = new Array<number>(30);
      this.stations.push(station);
    }
    // set information of first marked button so it can be modified by change
    this.buttonIdClickedBefore = 'button' + this.task.optimalAlgorithm;

    this.checkTargetCriterion();
    this.correctTimeLabel();
    this.checkKeyFigure();
    this.checkIfAllOperationsInGantt();
  }

  ngAfterViewChecked(): void {
    if (this.solutionUser.length > 0 && this.exponatType === 'EXPONAT2') {
      this.removeBorder();
    }
  }

  removeBorder(): void {
    // console.log('###### removeBorder #######');

    this.addborderagain();

    // console.log('removeBorder', this.solutionUser);
    // solitionUser[station][workstep]
    // usersolution + station + cell

    // User Solution
    for (let sta = 0; sta < this.solutionUser.length; sta++) {
      // stations
      for (const order of this.solutionUser[sta]) {
        // order
        for (let dur = 0; dur < order.workstep.duration; dur++) {
          // duration
          const test1 = document.getElementById('cell_solution' + 0 + sta + (order.workstep.startSolution + dur));
          const test3 = document
            .getElementById('cell_solution' + 0 + sta + (order.workstep.startSolution))
            .querySelectorAll<HTMLElement>('div.order-box-cell');
          // console.log('sta, dur', sta, dur);
          // console.log('test2', test2);
          let workstepnumber = 0;
          for (let i = 0; i < this.task.orderList[order.workstep.orderNumber].workstepList.length; i++) {
            if (
              this.task.orderList[order.workstep.orderNumber].workstepList[i].workstepId === order.workstep.workstepId
            ) {
              workstepnumber = i;
            }
          }
          if (order.workstep.duration === 1) {
            try {
              test3[0].textContent = 'o(' + (order.workstep.orderNumber + 1) + ', ' + (workstepnumber + 1) + ')';
              test3[0].style.fontWeight = 'bold';
              test3[0].style.display = 'flex';
              test3[0].style.alignItems = 'center';
              test3[0].style.justifyContent = 'center';
            } catch (e) {}
          } else {
            try {
              test3[1].textContent = 'o(' + (order.workstep.orderNumber + 1) + ', ' + (workstepnumber + 1) + ')';
              test3[1].style.fontWeight = 'bold';
              test3[1].style.display = 'flex';
              test3[1].style.alignItems = 'center';
              test3[1].style.justifyContent = 'center';
            } catch (e) {}
          }
          if (dur === 0) {
            test1.style.borderRight = 'none';
          } else if (dur === order.workstep.duration) {
            test1.style.borderLeft = 'none';
          } else {
            test1.style.borderRight = 'none';
            test1.style.borderLeft = 'none';
          }
          test1.style.backgroundImage = this.patterns[order.workstep.orderNumber];
          test1.style.backgroundSize = '9px 9px';
        }
      }
    }

    // console.log('removeBorder', this.solutionUser);
    // solitionUser[station][workstep]
    // usersolution + station + cell

    this.cleanGantt();

    // Given Solution
    for (let sta = 0; sta < this.ordersInAreaOrders.length; sta++) {
      // stations
      for (const order of this.ordersInAreaOrders[sta]) {
        // order
        for (let position = 0; position < order.workstep.duration; position++) {
          // duration
          const cell = document.getElementById('cell_solution' + 1 + sta + (order.workstep.startSolution + position));
          let workstepnumber = 0;

          // order.workstep.duration
          // order.workstep.orderNumber
          // order.workstep.startSolution
          let checkstation1;
          let checkstation2;
          if (sta === 0) {
            checkstation1 = 1;
            checkstation2 = 2;
          }
          if (sta === 1) {
            checkstation1 = 0;
            checkstation2 = 2;
          }
          if (sta === 2) {
            checkstation1 = 0;
            checkstation2 = 1;
          }
          for (const checkorder1 of this.ordersInAreaOrders[checkstation1]) {
            if (
              checkorder1.workstep.orderNumber === order.workstep.orderNumber &&
              checkorder1.workstep.duration !== 0
            ) {
              if (checkorder1.workstep.startSolution < order.workstep.startSolution) {
                workstepnumber += 1;
                break;
              }
            }
          }
          for (const checkorder2 of this.ordersInAreaOrders[checkstation2]) {
            if (
              checkorder2.workstep.orderNumber === order.workstep.orderNumber &&
              checkorder2.workstep.duration !== 0
            ) {
              if (checkorder2.workstep.startSolution < order.workstep.startSolution) {
                workstepnumber += 1;
                break;
              }
            }
          }

          // border
          if (position === 0) {
            cell.style.borderRight = 'none';
          } else if (position === order.workstep.duration) {
            cell.style.borderLeft = 'none';
          } else {
            cell.style.borderRight = 'none';
            cell.style.borderLeft = 'none';
          }

          // pattern + discription
          try {
            // let cell1 = document.getElementById('cell_solution' + 1 + sta + (order.workstep.startSolution + position));
            cell.style.backgroundImage = this.patterns[order.workstep.orderNumber - 1];
            cell.style.backgroundSize = '9px 9px';

            if (order.workstep.duration === 1 || order.workstep.duration === 2) {
              try {
                const cell1 = document.getElementById('cell_solution' + 1 + sta + (order.workstep.startSolution));
                cell1.textContent = 'o(' + order.workstep.orderNumber + ', ' + (workstepnumber + 1) + ')';
                cell1.style.fontWeight = 'bold';
                cell1.style.display = 'flex';
                cell1.style.alignItems = 'center';
                cell1.style.justifyContent = 'center';
              } catch (e) {}
            } else if (order.workstep.duration === 5) {
              try {
                const cell2 = document.getElementById('cell_solution' + 1 + sta + (order.workstep.startSolution + 2));
                cell2.textContent = 'o(' + order.workstep.orderNumber + ', ' + (workstepnumber + 1) + ')';
                cell2.style.fontWeight = 'bold';
                cell2.style.display = 'flex';
                cell2.style.alignItems = 'center';
                cell2.style.justifyContent = 'center';
              } catch (e) {}
            } else {
              try {
                const cell3 = document.getElementById('cell_solution' + 1 + sta + (order.workstep.startSolution + 1));
                cell3.textContent = 'o(' + order.workstep.orderNumber + ', ' + (workstepnumber + 1) + ')';
                cell3.style.fontWeight = 'bold';
                cell3.style.display = 'flex';
                cell3.style.alignItems = 'center';
                cell3.style.justifyContent = 'center';
              } catch (e) {}
            }
          } catch (e) {}
        }
      }
    }
  }

  // return the Order Number by given OrderID
  returnOrderNumberByID(id: string): number {
    for (let i = 0; i < this.task.orderList.length; i++) {
      if (id === this.task.orderList[i].orderId) {
        // console.log('solution-container -> ORDERNUMBER: ', i);
        return i;
      }
    }
  }

  addborderagain(): void {
    for (let sta = 0; sta < this.ordersInAreaOrders.length; sta++) {
      // stations
      for (let icell = 0; icell < 29; icell++) {
        // duration
        const cell = document.getElementById('cell_solution' + 1 + sta + icell);
        cell.style.borderRight = 'solid 1px';
        cell.style.borderLeft = 'solid 1px';
      }
    }
  }

  cleanGantt(): void {
    for (let sta = 0; sta < this.ordersInAreaOrders.length; sta++) {
      // stations
      for (let icell = 0; icell < 29; icell++) {
        // duration
        const cell = document.getElementById('cell_solution' + 1 + sta + icell);
        cell.style.backgroundImage = '';
        cell.textContent = '';
      }
    }
  }

  showSolutionFromSelectedAlgorithm(task: ITask): void {
    // console.log('this.ordersInAreaOrders', this.ordersInAreaOrders);
    this.ordersInAreaOrders = [];
    for (let i = 1; i <= this.stationCount; i++) {
      const ordersInStation = this.dataFormatService.getIOrderData(task, i, '0');
      this.ordersInAreaOrders.push(ordersInStation);
    }
    // Update KeyFigure
    this.targetCriterion = task.targetCriterion;
    this.keyFigureOptimal = this.dataFormatService.getKeyFigureData(task);
    this.checkTargetCriterion();
    this.correctTimeLabel();
    // this.checkKeyFigure();

    // remove border from button clicked before
    if (this.buttonIdClickedBefore !== undefined) {
      const buttonClickedBefore = document.getElementById(this.buttonIdClickedBefore);
      buttonClickedBefore.style.border = '';
    }
    // update style from clicked button
    const button = document.getElementById('button' + task.optimalAlgorithm);
    button.style.border = 'solid';
    this.buttonIdClickedBefore = 'button' + task.optimalAlgorithm;

    // this.removeBorder();
  }

  checkIfAllOperationsInGantt(): void {
    this.checkIfAllOrderInGantt();
  }

  // check if all orders in the gantt-diagram, for display the correct value
  checkIfAllOrderInGantt(): void {
    this.allOrdersInStation = true;

    for (let statNr = 0; statNr < this.stationCount; statNr++) {
      let numberOfOrdersUserSolution = 0; // Contains the real number,
      // as this.solutionUser[statNr].length may also contains dummy worksteps
      // for (let ordNr = 0; ordNr < this.solutionUser[statNr].length; ordNr++) {
      for (const solution of this.solutionUser[statNr]) {
        if (solution.workstep.duration !== 0 && solution.workstep.startSolution !== -1) {
          numberOfOrdersUserSolution++;
        }
      }

      for (const order of this.ordersInAreaOrders[statNr]) {
        // for (let j = 0; j < this.ordersInAreaOrders[statNr].length; j++) {
        if (order.workstep.duration === 0) {
          this.numberOfOrders = this.numberOfOrders - 1;
        }
      }
      if (this.numberOfOrders !== numberOfOrdersUserSolution) {
        this.allOrdersInStation = false;
        this.numberOfOrders = this.task.numberOfOrders;
        break;
      }
      this.numberOfOrders = this.task.numberOfOrders;
    }
  }

  checkIfAllWorkstepsInGantt(): void {
    this.allOrdersInStation = true;

    let numberOfTotalWorksteps = 0;
    let numberOfWorkstepsUserSolution = 0;
    for (let statNr = 0; statNr < this.stationCount; statNr++) {
      for (const solution of this.solutionUser[statNr]) {
        if (solution.workstep.duration !== 0 && solution.workstep.startSolution !== -1) {
          numberOfWorkstepsUserSolution++;
        }
      }
    }
    for (let j = 0; j < this.numberOfOrders; j++) {
      for (const workstep of this.task.orderList[j].workstepList) {
        if (workstep.duration !== 0) {
          numberOfTotalWorksteps++;
        }
      }
    }
    if (numberOfWorkstepsUserSolution !== numberOfTotalWorksteps) {
      this.allOrdersInStation = false;
    }
  }

  checkKeyFigure(): void {
    // Check for -1 -> KeyFigure calculation Error
    this.keyFigureValid = !(this.keyFigureUser.vMax === -1 &&
      this.keyFigureUser.vMid === -1 &&
      this.keyFigureUser.vSum === -1 &&
      this.keyFigureUser.totalProcessingTime === -1 &&
      this.keyFigureUser.avgProcessingTime === -1);
  }
  // check which timeLabel should be displayed
  correctTimeLabel(): void {
    switch (this.task.timeLabel) {
      case 'STUNDEN':
        this.timeLabel = 'Stunden';
        break;
      case 'MINUTEN':
        this.timeLabel = 'Minuten';
        break;
      default:
        break;
    }
  }
  // check if the targetCriterion is achieved to display the right feedback.
  // If the target is not reached calculation of the difference
  checkTargetCriterion(): void {
    if (this.task.targetCriterion === 'MIN_VMAX') {
      if (
        this.keyFigureOptimal.vMax === this.keyFigureUser.vMax ||
        this.keyFigureOptimal.vMax > this.keyFigureUser.vMax
      ) {
        this.targetAchieved = true;
      } else {
        this.differenceToTarget = this.keyFigureUser.vMax - this.keyFigureOptimal.vMax;
      }
    }
    if (this.task.targetCriterion === 'MIN_VSUM') {
      if (
        this.keyFigureOptimal.vSum === this.keyFigureUser.vSum ||
        this.keyFigureOptimal.vSum > this.keyFigureUser.vSum
      ) {
        this.targetAchieved = true;
      } else {
        this.differenceToTarget = this.keyFigureUser.vSum - this.keyFigureOptimal.vSum;
      }
    }
    if (this.task.targetCriterion === 'MIN_TOTALTIME') {
      if (
        this.keyFigureOptimal.totalProcessingTime === this.keyFigureUser.totalProcessingTime ||
        this.keyFigureOptimal.totalProcessingTime > this.keyFigureUser.totalProcessingTime
      ) {
        this.targetAchieved = true;
      } else {
        this.differenceToTarget = this.keyFigureUser.totalProcessingTime - this.keyFigureOptimal.totalProcessingTime;
      }
    }
  }

  back(goBack): void {
    this.modalService.open(goBack);
  }

  goBackToDashboard(): void {
    this.router.navigate(['/dashboard/1']);
  }

  async next(): Promise<void> {
    // check if there is another task in the taskId List
    for (let i = 0; i <= this.taskList.length; i++) {
      if (this.currentTask === this.taskList[i]) {
        if (this.taskList[i + 1] !== undefined) {
          this.nextTask = await this.dataService.getTask(this.taskList[i + 1]);
        }
      }
      // if there is another task, check if there is a task introduction for this task
      if (this.nextTask !== undefined) {
        if (this.nextTask.introduction !== '') {
          this.router.navigate(['/task-introduction', this.taskList[i + 1]]);
          return;
        } else {
          this.router.navigate(['/task', this.taskList[i + 1]]);
          return;
        }
      }
    }
    // if there are no more tasks in the list, navigate back to the dashboard
    this.router.navigate(['/dashboard/1']);
  }

  displayAlgorithmInfo(algorithmInfoDialog): void {
    this.dialog.open(algorithmInfoDialog);
  }
}
