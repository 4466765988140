import {Injectable} from '@angular/core';

import {IKeyfigure} from './IKeyfigure';
import {IOrder} from './IOrder';
import {ITask} from './ITask';
import {IWorkstep} from './IWorkstep';

@Injectable()
export class FormatDataService {
  // get back an array of orders for each station
  public getIOrderData(task: ITask, station, exponatType: string): IOrder[] {
    // List of colors for each order. Expand the list of colors to offer more orders
    let colors;
    if ( exponatType == 'EXPONAT2' ) {
      colors = ['RGB( 255, 255, 255 )', 'RGB( 255, 255, 255 )', 'RGB( 255, 255, 255 )', 'RGB( 255, 255, 255 )',
        'RGB( 255, 255, 255 )', 'RGB( 255, 255, 255 )', 'RGB( 255, 255, 255)', 'RGB( 255, 255, 255 )'];
    } else if ( exponatType == 'EXPONAT1' || exponatType == '0') {
      colors = ['RGB( 68, 114, 196 )', 'RGB( 112, 173, 71 )', 'RGB( 255, 192, 0 )', 'RGB( 237, 125, 49 )',
        'RGB( 199, 229, 14 )', 'RGB( 245, 75, 162 )', 'RGB( 177, 103, 233)', 'RGB( 0, 176, 240 )'];
    }
    // List of orderNumber for each order. Expand the list of orderNumber to offer more orders.
    // Is needed to validate the user input during drag-and-drop
    const orderNumber = [1, 2, 3, 4, 5, 6, 7, 8];
    // create a list of orders for each station
    const ordersStation = task.orderList.map(x => {
      return {
        orderId: x.orderId,
        workstepList: x.workstepList.filter(y => y.station === station),
        targetDate: x.targetDate,
        releaseDate: x.releaseDate,
      } as IOrder;
    }).map(x => {
      return {
        orderId: x.orderId,
        targetDate: x.targetDate,
        releaseDate: x.releaseDate,
        workstep: {
          workstepId: x.workstepList.find(y => y.station === station).workstepId,
          startSolution: x.workstepList.find(y => y.station === station).startSolution,
          duration: x.workstepList.find(y => y.station === station).duration,
          priority: x.workstepList.find(y => y.station === station).priority,
          station: x.workstepList.find(y => y.station === station).station,
          color: colors.shift(),
          isDragDisabled: (x.workstepList.find(y => y.station === station).duration <= 0),
          orderNumber: orderNumber.shift(),
        }
      } as IOrder;
    });
    return ordersStation;
  }

  public getKeyFigureData(task: ITask): IKeyfigure {
    const keyFigureData =
      {
        keyFigureId: task.keyFigure.keyFigureId,
        vMax: task.keyFigure.vMax,
        vMid: task.keyFigure.vMid,
        vVar: task.keyFigure.vVar,
        vSum: task.keyFigure.vSum,
        totalProcessingTime: task.keyFigure.totalProcessingTime,
        avgProcessingTime: task.keyFigure.avgProcessingTime,
      } as IKeyfigure;
    return keyFigureData;
  }

  // combined all worksteps of the orders of each station to send the solved task back to the backend for calculation.
  // Type ITask is needed for the post-request!
  public getTaskUserSolution(task: ITask, orderInStation: IOrder[][], exponatType: string, dynamic: boolean): ITask {
    let allOrderInStation;
    if ( exponatType === 'EXPONAT2' ) {
      if (dynamic) {
        allOrderInStation = [];
        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < task.orderList.length; i++) {
          allOrderInStation.push(this.deepCopyOfOrder(task.orderList[i]));
          // tslint:disable-next-line:prefer-for-of
          for (let l = 0; l < task.orderList[i].workstepList.length; l++) {
            const ws = this.deepCopyOfWorkstep(task.orderList[i].workstepList[l]);
            // if duration is 0 -> ignore these worksteps because they are not needed for calculations in the backend
            // and otherwise the whole order will be ignored because of this filling operation
            if (ws.duration === 0) {
              continue;
            }
            ws.startSolution = -1;
            allOrderInStation[i].workstepList.push(ws);
          }
        }
        for (let k = 0; k < task.numberOfStations; k++) {
          // tslint:disable-next-line:prefer-for-of
          for (let j = 0; j < orderInStation[k].length; j++) {
            const workstepOfOrder = orderInStation[k][j].workstep;
            const orderNo = this.returnOrderNumberByWorkstepID(workstepOfOrder.workstepId, task);
            // tslint:disable-next-line:prefer-for-of
            for (let l = 0; l < allOrderInStation[orderNo - 1].workstepList.length; l++) {
              if (allOrderInStation[orderNo - 1].workstepList[l].workstepId === workstepOfOrder.workstepId) {
                if (workstepOfOrder.isProcessed === true) {
                  allOrderInStation[orderNo - 1].workstepList[l] = workstepOfOrder;
                }
              }
            }
          }
        }
      } else {
        allOrderInStation = task.orderList;
      }
    } else {
      const allWorksteps = orderInStation.flatMap(x => x).map(x => ({
        Workstep: x.workstep, OrderId: x.orderId
      }));

      allOrderInStation = orderInStation.flatMap(x => x);

      allOrderInStation.forEach(x => {
        x.workstepList = allWorksteps.filter(y => x.orderId === y.OrderId).map(y => y.Workstep);
      });
    }

    const taskUserSolution =
      {
        description: task.description,
        introduction: task.introduction,
        introductionVideo: task.introductionVideo,
        difficutlyLevel: task.difficultyLevel,
        keyFigure: {
          keyFigureId: task.keyFigure.keyFigureId,
          vMax: undefined,
          vMin: undefined,
          vMid: undefined,
          vSum: undefined,
          totalProcessingTime: undefined,
          avgProcessingTime: undefined,
        },
        numberOfOrders: task.numberOfOrders,
        numberOfStations: task.numberOfStations,
        maxNumberWorksteps: task.maxNumberWorksteps,
        stationPictureList: task.stationPictureList,
        descOfStationList: task.descOfStationList,
        orderList: allOrderInStation,
        physicalAvailable: false,
        productionType: task.productionType,
        solutionType: 0,
        taskId: task.taskId,
        timeFactor: task.timeFactor,
        timeLabel: task.timeLabel,
        targetCriterion: task.targetCriterion,
        optimalAlgorithm: task.optimalAlgorithm,
      } as ITask;

    return taskUserSolution;
  }

  // Returns a deep copy of the Order
  deepCopyOfOrder(oldOrder: IOrder): IOrder {
    const newOrder = {
      orderId: oldOrder.orderId,
      name: oldOrder.name,
      image: oldOrder.image,
      releaseDate: oldOrder.releaseDate,
      targetDate: oldOrder.targetDate,
      workstepList: []
    } as IOrder;
    return newOrder;
  }

   // Returns a deep copy of the Workstep
  deepCopyOfWorkstep(oldWorkstep: IWorkstep): IWorkstep {
    const newWorkstep = {
      workstepId: oldWorkstep.workstepId,
      physicalId: oldWorkstep.physicalId,
      station: oldWorkstep.station,
      duration: oldWorkstep.duration,
      priority: oldWorkstep.priority,
      startSolution: oldWorkstep.startSolution,
      color: oldWorkstep.color,
      isDragDisabled: oldWorkstep.isDragDisabled,
      orderNumber: oldWorkstep.orderNumber,
      isInProcess: oldWorkstep.isInProcess,
      isProcessed: oldWorkstep.isProcessed
    } as IWorkstep;
    return newWorkstep;
  }

  returnOrderNumberByWorkstepID(id: string, task: ITask): number {
    for (let i = 0; i < task.orderList.length; i++) {
      for (const workstep of task.orderList[i].workstepList) {
        if (id === workstep.workstepId) {
          // console.log('returnOrderNumberByWorkstepID -> ORDERNUMBER: ', i + 1 );
          return i + 1;
        }
      }
    }
  }
}
