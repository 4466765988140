<div>
  <div class="gantt" *ngFor="let col of [].constructor(this.stationCount); let i = index">
    <p class="station-name" style="margin: 0">{{ task.descOfStationList[i] }}</p>
    <div class="container-gantt-solution">
      <div *ngFor="let row of this.stations[i]; let a = index">
        <div class="cell_solution">
          <ng-container *ngFor="let order of orders[i]">
            <div *ngIf="order.workstep.startSolution === a">
              <div class="line" [style.backgroundColor]="order.workstep.color" style="position: absolute">
                <ng-template #tooltipList>
                  <div style="text-align: left" class="d-flex flex-column">
                    <span>Auftr.: {{ order.workstep.orderNumber }} </span>
                    <span>t: {{ order.workstep.duration * task.timeFactor }}</span>
                  </div>
                </ng-template>

                <div class="order-box order-tooltip" triggers="click" [autoClose]="'inside'" [ngbTooltip]="tooltipList">
                  <!-- for more orders expand the list -->
                  <div
                    *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8].slice(0, order.workstep.duration)"
                    class="order-box-cell"
                  ></div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
        <p class="timeline">{{ a * task.timeFactor }}</p>
      </div>

      <div>
        <div class="cell_solution_noBorder"></div>
        <div *ngIf="task.timeLabel === 'MINUTEN'">
          <p class="timeline" style="font-weight: bold; text-align: center">Minuten</p>
        </div>
        <div *ngIf="task.timeLabel === 'STUNDEN'">
          <p class="timeline" style="font-weight: bold; text-align: center">Stunden</p>
        </div>
        <div *ngIf="task.timeLabel === 'ZE'">
          <p class="timeline" style="font-weight: bold; text-align: center">ZE</p>
        </div>
      </div>
    </div>
  </div>
</div>
