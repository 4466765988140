import {NgModule} from '@angular/core';
import {RouterModule,Routes} from '@angular/router';

import {DashboardComponent} from './dashboard-module/dashboard-container/dashboard.component';
import {ExponatRessourcenbelegungsplanungSolutionContainerComponent} from './exponatRessourcenbelegungsplanung/solution/container/container.component'
import {ExponatRessourcenbelegungsplanungTaskContainerComponent} from './exponatRessourcenbelegungsplanung/task/container/container.component';
import {CaseStudyInfoComponent} from './information-module/case-study-info/case-study-info.component';
import {TaskIntroductionComponent} from './information-module/task-introduction/task-introduction.component';

const routes: Routes = [
  { path: '', redirectTo: '/dashboard/1', pathMatch: 'full'},
  { path: 'exponat1', redirectTo: '/dashboard/1', pathMatch: 'full'},
  { path: 'dashboard/:id', component: DashboardComponent },
  { path: 'caseStudyInfo', component: CaseStudyInfoComponent},
  { path: 'task-introduction/:id', component: TaskIntroductionComponent},

  // Festes Routing auf die einzelnen Exponate
  { path: '1/task/:id', component: ExponatRessourcenbelegungsplanungTaskContainerComponent },
  { path: '1/solution/:id', component: ExponatRessourcenbelegungsplanungSolutionContainerComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
